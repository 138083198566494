import React from "react";

import "leaflet/dist/leaflet.css";

import Menu1 from "./components/Menu";

import withRouter from "./withRouter";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./App.css";
import config from "./config.js";

import { StoreContext } from "./store/RootStore";
import { observer } from "mobx-react";
import Cookies from "js-cookie";
import { Helmet } from "react-helmet";
class App extends React.Component {
  constructor(props, context) {
    super(props);

    this.state = { collapsed: false, marginLeft: 80, settingColor: "white" };
    // console.log(props);
    let isArabic = Number(JSON.parse(JSON.stringify(Cookies.get())).isArabic);
    // console.log(isArabic);
    isArabic ? context.setLocale("arabic") : context.setLocale("english");
  }
  handlePopState = () => {
    // For example, navigate to the login page
    this.props.navigate("/login");
  };
  async componentDidMount() {
    window.addEventListener("popstate", this.handlePopState);
  }

  updateTitle = () => {
    if (config.project === "drive7") {
      return "Drive7";
    } else if (config.project === "awtltrack") {
      return "AWTL";
    } else if (config.project === "starlingeye") {
      return "Starlingeye";
    } else if (config.project === "trackeazy") {
      return "Trackeazy";
    } else {
      return "Trackeazy";
    }
  };

  updateIcons = () => {
    if (config.project === "drive7") {
      return {
        favicon: "/drive7.ico",
        appleTouchIcon: "/drive7.ico",
      };
    } else if (config.project === "awtltrack") {
      return {
        favicon: "/awtl.ico",
        appleTouchIcon: "/awtl.ico",
      };
    } else if (config.project === "starlingeye") {
      return {
        favicon: "/starling.ico",
        appleTouchIcon: "/starling.ico",
      };
    } else if (config.project === "trackeazy") {
      return {
        favicon: "/favicon.ico",
        appleTouchIcon: "/favicon.ico",
      };
    } else {
      return {
        favicon: "/favicon.ico",
        appleTouchIcon: "/favicon.ico",
      };
    }
  };
  render() {
    const { favicon, appleTouchIcon } = this.updateIcons();
    return (
      <>
        <Helmet>
          <title>{this.updateTitle()}</title>
          <link rel="icon" href={favicon} />
          <link rel="apple-touch-icon" href={appleTouchIcon} />
        </Helmet>
        <div className={config.project === "drive7" ? "drive7-wrapper" : ""}>
          <Menu1 />
        </div>
      </>
    );
  }
}

// export default withRouter(App);
App.contextType = StoreContext;
export default observer(withRouter(App));
