import React from "react";
import {
  Form,
  DatePicker,
  Input,
  Select,
  Button,
  Space,
  Row,
  Col,
  Dropdown,
  message,
  Upload,
  Modal,
  Tooltip,
  Progress,
  Image,
} from "antd";
import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";
import { Link } from "react-router-dom";
import DisplayLocationMap from "./DisplayLocationMap";
import {
  DeleteFilled,
  SearchOutlined,
  UploadOutlined,
  PlusOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import config from "../config";

import withRouter from "../withRouter";
import DeleteConfirm from "./DeleteConfirm";
import CompanyAutocomplete from "./CompanyAutocomplete";
import JobManagementAdd from "./JobManagementAdd";
import ListTable from "./ListTable";

import JobManagementStore from "../store/JobManagementStore";

import DriversStore from "../store/DriversStore";
import VehicleStore from "../store/VehicleStore";
import dayjs from "dayjs";
import CompanyStore from "../store/CompanyStore";
import DeliveryDashboardStore from "../store/DeliveryDashboardStore";
import JobManagementListFields from "./Plugins/AddJobManagement/JobManagementListFields";
let widthAdderFunc = (arr) => {
  let array = arr;
  array.map((val) => {
    if (val.title?.length > 24) {
      val.width = 230;
    } else if (val.title?.length > 15) {
      val.width = 200;
    } else if (val.title?.length > 12) {
      val.width = 130;
    } else if (val.title?.length > 8) {
      val.width = 110;
    } else {
      val.width = 80;
    }
  });

  return arr;
};
const { Option } = Select;
const currentDate = dayjs().format("MM-DD-YYYY");

class JobManagementList extends React.Component {
  constructor(props) {
    super(props);

    this.handleTableChange = this.handleTableChange.bind(this);
    this.jobManagementStore = new JobManagementStore(this);
    this.driverStore = new DriversStore(this);
    this.vehicleStore = new VehicleStore(this);
    this.companyStore = new CompanyStore(this);
    this.deliveryDashboardStore = new DeliveryDashboardStore(this);

    this.state = {
      loading: false,
      pageSize: 50,
      currentPage: 1,
      openConfirm: false,
      confirmLoading: false,
      loadList: 0,
      warehouseList: [],
      companyId: "",
      showUpload: false,
      showDeleteModal: false,
      // list search component
      vehicleId: "",

      deliveryDateFrom: dayjs().format("YYYY-MM-DD"),
      deliveryDateTo: dayjs().format("YYYY-MM-DD"),
      driverName: "",
      salesExecutive: "",
      customerName: "",
      showModal: false,
      jobId: "",

      showmap: false,

      companyVal_Lab: "",
      openJobmanagementForm: false,
      listDataUpdate: false,
      Id: "",
      showAlertModal: false,

      fileList: [],
      uploading: false,
      uploadProgress: 0,
      showCancelJobModal: false,
      cancelJobId: "",
      // excelFile: '',
    };
  }
  onEdit = async (record) => {
    const Id = record.Id;

    if (Id) {
      await this.context.viewStateStore.setJobManagementStates(
        this.state.companyId,
        this.state.driverName,
        this.state.vehicleId,
        this.state.salesExecutive
        //  this.state.deliveryDateFrom,
        //  this.state.deliveryDateTo,
      );
      // await this.props.navigate("/jobmanagementadd/" + Id);
      await this.setState({ Id: record.Id });
      await this.setState({ openJobmanagementForm: true });
    }

    // const id = record.Id;
    // this.props.navigate("/jobmanagementadd/" + id);
  };

  onDelete = async (record) => {
    const response = await this.jobManagementStore.deleteRecord({
      Id: record.Id,
      companyId: record.companyId,
    });

    if (response?.data?.success === "S") {
      message.success(response.data.message);
      this.handleSearch();
    } else {
      message.error(response.data.message);
    }
  };
  async componentDidMount() {
    if (Number(this.props.getUserData().isadmin) !== -1) {
      await this.setState({
        companyId: this.props.getUserData().userCompanyId,
      });
      await this.setState({ userId: this.props.getUserData().userId });
      await this.jobManagementStore.getAllVehicles(
        this.props.getUserData().userCompanyId
      );
      await this.jobManagementStore.getDriversByCompanyId(
        this.props.getUserData().userCompanyId
      );
      await this.jobManagementStore.getSalesPersonExecutive({
        companyId: this.props.getUserData().userCompanyId,
      });
      // await this.handleSearch();
    }

    this.setState({ pageSize: 50 });
    this.menuProps = {
      items: [
        {
          // label: "Driver",
          label: this.context.translate("_FLEET_MANAGEVEHICLE_DRIVER_TAB_"),
          key: "driver",
        },
        {
          // label: "Sales Person",
          label: this.context.translate("_SALES_PERSON_"),
          key: "salesPerson",
        },
      ],
      onClick: (val) => {
        if (val.key == "driver") {
          this.jobManagementStore.sampleExcelDriver();
        }
        if (val.key == "salesPerson") {
          this.jobManagementStore.sampleExcelSalesPerson();
        }
      },
    };

    this.columns = widthAdderFunc([
      {
        title: this.context.translate("_JOB_NO_"),
        dataIndex: "Id",
        width: "15%",
      },
      {
        // title: "Vehicle Number",
        title: this.context.translate("_VEHICLE_NO_"),
        dataIndex: "vehicleNo",
        width: "15%",
        render: (val, record) => {
          return (
            <Link
              onClick={(e) => {
                e.preventDefault();
                this.onEdit(record);
              }}
            >
              {val}
            </Link>
          );
        },
      },
      {
        // title: "Driver Name",
        title: this.context.translate(
          "_FLEET_DASHBORD_VEHICLE_INFORMATION_DRIVERNAME_"
        ),
        dataIndex: "name",
        width: "15%",
      },
      {
        // title: "Mobile No",
        title: this.context.translate(
          "_FLEET_DASHBORD_VEHICLE_INFORMATION_MOBILE_"
        ),
        dataIndex: "mobileno",
        width: "15%",
      },
      {
        // title: "Customer Name ",
        title: this.context.translate("_CUSTOMER_NAME_"),
        dataIndex: "customerName",
        width: "15%",
      },
      {
        // title: "Customer Name ",
        title: this.context.translate("_CUSTOMER_CODE_"),
        dataIndex: "customerCode",
        width: "15%",
      },
      {
        // title: "Customer Mobile No",
        title: this.context.translate("_CUST_MOBILENO_"),
        dataIndex: "customerMobile",
        width: "15%",
      },
      {
        // title: "Sales Executive Name",
        title: this.context.translate("_SALES_EXECUTIVE_NAME_"),
        dataIndex: "SalesExecutiveName",
        width: "15%",
      },
      {
        // title: "Sales Coordinator Name",
        title: this.context.translate("_SALES_COORDINATOR_NAME_"),
        dataIndex: "SalesCoordinatorName",
        width: "15%",
      },
      {
        // title: "Job To",
        title: this.context.translate("_JOB_TO_"),
        dataIndex: "jtype",
        width: "15%",
      },
      {
        // title: "Job Date",
        title: this.context.translate("_JOB_DATE_"),
        dataIndex: "deliveryDate",
        width: "15%",
      },
      {
        // title: "Job Time",
        title: this.context.translate("_JOB_TIME_"),
        dataIndex: "deliveryTime",
        width: "15%",
      },
      {
        // title: "Job Type",
        title: this.context.translate("_JOB_TYPE_"),
        dataIndex: "pickupDelivery",
        width: "15%",
      },
      {
        // title: "Job Type",
        title: this.context.translate("_ROUTE_ID_"),
        dataIndex: "routeId",
        width: "15%",
      },
      {
        // title: "Job Type",
        title: this.context.translate("_ROUTE_NAME_"),
        dataIndex: "routeName",
        width: "15%",
      },
      {
        // title: "Job Type",
        title: this.context.translate("_CREATED_DATE_"),
        dataIndex: "pickupDelivery",
        width: "15%",
      },
      {
        // title: "Status",
        title: this.context.translate("_FLEET_DASHBORD_DROWGEO_ALERTSTATUS_"),
        dataIndex: "status",
        width: "15%",
        render: (val) => {
          if (val == 3) {
            return "Finished";
          } else if (val == 4) {
            return "Cancelled";
          } else {
            return "Pending";
          }
        },
      },
      {
        // title: "Status",
        title: this.context.translate("_ORDER_STATUS1_"),
        dataIndex: "orderStatus",
        width: "15%",
      },
      {
        // title: "Action",
        title: this.context.translate("_ACTION_"),
        dataIndex: "groupname",
        render: (_, record) => {
          return (
            <>
              <Space>
                {/* <Link
                  onClick={(e) => {
                    e.preventDefault();
                    this.onEdit(record);
                  }}
                >
                  <Tooltip
                    // title="Edit"
                    title={this.context.translate("_FLEET_COMPANY_COMPANYLIST_EDIT_")}
                    placement="bottom"
                    color={config.tooltipColorEdit}
                  >
                    <EditFilled />
                  </Tooltip>
                </Link> */}
                <DeleteConfirm
                  onConfirm={(e) => {
                    e.preventDefault();
                    this.onDelete(record);
                  }}
                >
                  <Link>
                    <Tooltip
                      // title="Delete"
                      title={this.context.translate(
                        "_FLEET_COMPANY_COMPANYLIST_DELETE_"
                      )}
                      placement="bottom"
                      color={config.tooltipColorDelete}
                    >
                      <DeleteFilled style={{ color: config.deleteColor }} />
                    </Tooltip>
                  </Link>
                </DeleteConfirm>
                {record.status != 4 && (
                  <Tooltip
                    // title="Delete"
                    title={this.context.translate("_CANCEL_JOB_")}
                    placement="bottom"
                    color={config.tooltipColorEdit}
                  >
                    <Image
                      width={13}
                      src={require("../assets/images/cancel.png")}
                      onClick={(e) => {
                        this.setState({ showCancelJobModal: true });
                        this.setState({ cancelJobId: record.Id });
                      }}
                    ></Image>
                  </Tooltip>
                )}
              </Space>
            </>
          );
        },
        width: "10%",
      },
    ]);
  }

  async componentDidUpdate(prevProps, prevState) {
    if (this.state.showmap !== prevState.showma) {
      setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
      });
    }
    if (prevState.listDataUpdate !== this.state.listDataUpdate) {
      const setJobData = this.context.viewStateStore.jobManagementObject;

      if (setJobData.companyId !== "") {
        await this.jobManagementStore.loadData({
          offset: (this.state.currentPage - 1) * this.state.pageSize,
          limit: this.state.pageSize,
          companyId: setJobData.companyId,
          vehicleId: setJobData.vehicleId,
          driverName: setJobData.driverName,
          salesExecutive: setJobData.salesExecutive,
          deliveryDateFrom: this.state.deliveryDateFrom,
          deliveryDateTo: this.state.deliveryDateTo,
        });

        const data = await this.jobManagementStore.getAllVehicles(
          setJobData.companyId
        );

        await this.setState({ vehicleId: setJobData.vehicleId });

        await this.jobManagementStore.getDriversByCompanyId(
          setJobData.companyId
        );
        await this.setState({ driverName: setJobData.driverId });

        await this.jobManagementStore.getSalesPersonExecutive({
          companyId: setJobData.companyId,
        });
        await this.setState({ salesExecutive: setJobData.salespersonId });

        const singleData = await this.companyStore.getCompanyDataById(
          setJobData.companyId
        );

        await this.setState({ loadList: 1 });

        await this.setState({
          companyVal_Lab: singleData,
          companyId: setJobData.companyId,
        });
      }
      await this.setState({ listDataUpdate: false });
    }
  }
  async handleTableChange(pagination, filters, sorter) {
    await this.setState({
      currentPage: pagination.current,
      pageSize: pagination.pageSize,
    });
    await this.handleSearch();
  }
  async handleChange(comp) {
    await this.setState({
      companyId: comp.value,
      vehicleId: "",
      salesExecutive: "",
      driverName: "",
      jobId: "",
    });
    const singleData = await this.companyStore.getCompanyDataById(comp.value);
    await this.setState({ companyVal_Lab: singleData, companyId: comp.value });

    await this.jobManagementStore.getAllVehicles(comp.value);
    await this.jobManagementStore.getDriversByCompanyId(comp.value);
    await this.jobManagementStore.getSalesPersonExecutive({
      companyId: comp.value,
    });

    await this.handleSearch();
    await this.setState({ loadList: 1 });
  }

  async handleSearch() {
    this.setState({ loading: true });
    await this.jobManagementStore.loadData({
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      companyId: this.state.companyId,
      vehicleId: this.state.vehicleId,
      deliveryDateFrom: this.state.deliveryDateFrom,
      deliveryDateTo: this.state.deliveryDateTo,
      driverName: this.state.driverName,
      salesExecutive: this.state.salesExecutive,
      customerName: this.state.customerName,
    });

    await this.setState({ loadList: 1 });
  }
  searchClick = async () => {
    if (!this.state.companyId) {
      this.setState({ companyFieldHighlight: true });
    } else {
      this.setState({ companyFieldHighlight: false });
    }
    if (this.state.companyId) {
      this.setState({ companyFieldHighlight: false, loading: true });

      await this.jobManagementStore.loadData({
        offset: (this.state.currentPage - 1) * this.state.pageSize,
        limit: this.state.pageSize,
        companyId: this.state.companyId,
        vehicleId: this.state.vehicleId,
        deliveryDateFrom: this.state.deliveryDateFrom,
        deliveryDateTo: this.state.deliveryDateTo,
        driverName: this.state.driverName,
        salesExecutive: this.state.salesExecutive,
        customerName: this.state.customerName,
      });
      await this.setState({ loadList: 1 });
    }
  };

  getUploadData = async (fileUp) => {
    // if (fileUp.status !== 'uploading') {

    let fileinfo = await fileUp.file.response;

    if (fileinfo?.success == "S") {
      await this.setState({ excelFile: fileinfo.data });
    }

    this.setState({ update: 1 });
  };

  onExcelSubmit = async (fieldsValue) => {
    const data = {
      ...fieldsValue,
      companyId: this.state.companyId,
      excelFile: this.state.excelFile,
    };

    const resp = await this.jobManagementStore.addDriverByExcel(data);
    // this.setState({loading:true})

    if (resp?.success == "S") {
      message.success(resp.message);

      this.setState({ showUpload: false });
      this.setState({ excelFile: "" });
    } else {
      message.error(resp.errors[0].error);
    }

    // this.setState({loading:false})
    // if (resp.message) {
    //   this.setState({ showUpload: false });
    //   this.formRef.current?.resetFields();
    // }
  };

  finishJob = async (value) => {
    const data = {
      JobIdArray: value,
    };

    const resp = await this.jobManagementStore.finishJob(data);

    if (resp?.success == "S") {
      message.success(resp.message);
      await this.handleSearch();
    }
    if (resp.message) {
      this.setState({ showUpload: false });
      this.setState({ jobId: [] });
      // this.formRef.current?.resetFields();
    } else {
      if (resp.errors[0]) {
        message.error(resp.errors[0]);
      }
    }
  };

  deleteJob = async (value) => {
    let flag = 0;

    const deleteJob = value.forEach(async (id) => {
      const data = {
        Id: id,
        companyId: this.state.companyId,
      };

      const response = await this.jobManagementStore.deleteRecord(data);

      if (response?.data?.success === "S") {
        if (flag === 0) {
          message.success(response.data.message);
        }

        this.handleSearch();
        flag++;
      } else {
        message.error(response.data.message);
      }
    });
  };

  show = () => {
    this.setState({ showmap: true });
  };

  // warningForDeleteJob = async () => {
  //   Modal.warning({
  //     title: "Alert",
  //     content: "Please select job",
  //   });
  // };

  responseMsg = async (resp) => {
    if (resp.success == "S") {
      message.success(resp.message);
      await this.setState({ listDataUpdate: true });
      await this.setState({ openJobmanagementForm: false });

      await this.handleSearch();
      // this.props.navigate("/companyuserlist");
    } else {
      message.error(resp.message);
    }
  };

  closeModal = () => {
    this.setState({ openJobmanagementForm: false, Id: "" });
  };

  handleReasonToCancel = async (val) => {
    console.log(val);
    const data = {
      tokenId: "",
      companyId: this.state.companyId,
      userId: 1,
      Id: this.state.cancelJobId,
      reason: val.reason,
    };

    const res = await this.jobManagementStore.cancelJob(data);
    if (res.success === "S") {
      message.success(res.message);
      await this.handleSearch();
      this.setState({ showCancelJobModal: false, showCofirmJobModal: false });
    }
  };

  handleDashboardClick = async ()=>{
    await this.deliveryDashboardStore.loadData({
      companyId: this.props.getUserData().userCompanyId,
      fromDate: dayjs().format("YYYY-MM-DD"),
      toDate: dayjs().format("YYYY-MM-DD"),
      filter: "all",
      datefilter: dayjs().format("YYYY-MM-DD"),
      statusFilter: null,
      invoiceDate: "",
    });
  }

  render() {
    const companyInputBox = this.state.companyFieldHighlight
      ? { border: "2px solid red", width: "150px", borderRadius: "8px" }
      : { width: "150px" };

    return (
      <>
        <div style={{ padding: 0 }}>
          <Row className="headerFixed" style={{ height: "50px" }}>
            <Col span={12}>
              <div className="heading">
                {this.context.translate("_JOB_MANAGEMENT_")}
              </div>
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              >
                {/* <AddButton
                  link="/jobmanagementadd"
                  title={this.context.translate("_ADD_")}
                /> */}

                <Tooltip
                  // title="Add"
                  title={this.context.translate("_FLEET_ROLE_ROLELIST_ADDBTN_")}
                  placement="left"
                  color={config.primaryColor}
                >
                  <Button
                    className="addButton"
                    icon={<PlusOutlined />}
                    type="primary"
                    onClick={() =>
                      this.setState({ openJobmanagementForm: true, Id: "" })
                    }
                  ></Button>
                </Tooltip>

              
               <JobManagementListFields handleDashboardClick={this.handleDashboardClick }/>


                <Button
                  type={config.uploadBoxType}
                  onClick={(e) => {
                    this.setState({ showUpload: true });
                  }}

                  // onClick={(e) => {
                  //   console.log(e);
                  //   this.setState({ showUpload: true });
                  // }}
                >
                  <i class="bi bi-upload" style={{ marginRight: "5px" }}></i>
                  {/* <UploadOutlined /> */}
                  {/* Upload */}
                  {this.context.translate("_UPLOAD_")}
                </Button>

                <Button
                  onClick={async () => {
                    this.state.jobId.length > 0
                      ? await this.setState({ showModal: true })
                      : await this.setState({ showAlertModal: true });
                  }}
                  // onClick={() => this.setState({ showModal: true })}
                >
                  {/* Do you want to finish job? */}
                  {this.context.translate("_FINISH_JOB_")}
                </Button>
                {/* <Link to="/dashboardpage">
                  <Button>
                    <i
                      style={{ marginRight: "5px" }}
                      class="bi bi-clipboard-data"
                    ></i>
                  
                    {this.context.translate("_DASHBOARD_")}
                  </Button>
                </Link> */}

                {/* <Button
                  onClick={() => {
                    this.setState({ showmap: true });
                  }}
                >
                  <i style={{ marginRight: "5px" }} class="bi bi-pin-map"></i>
                
                  {this.context.translate("_CHECK_ROUTE_")}
                </Button> */}
                <Button
                  onClick={async () => {
                    this.state.jobId.length > 0
                      ? await this.setState({ showDeleteModal: true })
                      : await this.setState({ showAlertModal: true });
                  }}
                >
                  <i class="bi bi-trash3" style={{ marginRight: "5px" }}></i>
                  {/* <DeleteFilled /> */}
                  {/* Delete */}
                  {this.context.translate(
                    "_FLEET_DASHBORD_RULETRIGGER_DELETE_"
                  )}
                </Button>

                {/* <Dropdown menu={this.menuProps}>
                  <Button>
                    <Space>
                      {this.context.translate("_ACTION_")}
                      <DownOutlined />
                    </Space>
                  </Button>
                </Dropdown> */}
              </Space>
            </Col>
          </Row>
        </div>

        <div
          style={{
            paddingTop: "10px",
            paddingRight: "24px",
            paddingLeft: "24px",
          }}
        >
          <Row className="listInputRow">
            <Col span={23}>
              <Space>
                <CompanyAutocomplete
                  value={
                    this.state.companyVal_Lab ? this.state.companyVal_Lab : null
                  }
                  onChange={(value) => this.handleChange(value)}
                  // allowClear={true}
                  style={{ width: 140 }}
                  // style={companyInputBox}
                  status={this.state.companyFieldHighlight ? "error" : false}
                />

                {/* <Select
                  value={this.state.vehicleId ? this.state.vehicleId : null}
                  // placeholder="Select Vehicle"
                  placeholder={this.context.translate("_SELECT_VEHICLE_")}
                  style={{ width: 140 }}
                  onSelect={async (val) => {
                    await this.setState({ vehicleId: val });
                    this.handleSearch();
                  }}
                  showSearch
                  fieldNames={{ label: "vehicleNo", value: "vehicleId" }}
                  optionFilterProp="children"
                  options={this.jobManagementStore?.VehicleList}
                /> */}

                <Select
                  value={this.state.vehicleId ? this.state.vehicleId : null}
                  placeholder={this.context.translate("_SELECT_VEHICLE_")}
                  style={{ width: 140 }}
                  onSelect={async (val) => {
                    await this.setState({ vehicleId: val });
                    this.handleSearch();
                  }}
                  showSearch
                  optionFilterProp="children"
                  // options={this.vehicleWorkingHoursStore?.assignAssetList}
                  filterOption={(inputValue, option) => {
                    if (option.children) {
                      return (
                        option.children
                          .toLowerCase()
                          .indexOf(inputValue.toLowerCase()) >= 0
                      );
                    }
                  }}
                >
                  {this.jobManagementStore?.VehicleList?.map((val) => {
                    return (
                      <Option value={val.vehicleId}>{val.vehicleNo}</Option>
                    );
                  })}
                </Select>

                <Select
                  value={
                    this.state.salesExecutive ? this.state.salesExecutive : null
                  }
                  // placeholder="Select Sales Executive"
                  placeholder={this.context.translate(
                    "_SELECT_SALES_EXECUTIVE_"
                  )}
                  style={{ width: 190 }}
                  showSearch
                  onSelect={async (val) => {
                    await this.setState({ salesExecutive: val });
                    this.handleSearch();
                  }}
                  fieldNames={{
                    label: "SalesExecutiveName",
                    value: "salespersonId",
                  }}
                  optionFilterProp="children"
                  options={
                    this.jobManagementStore?.getSalesPersonExecutiveArray
                  }
                />

                {/* <Select
                  value={this.state.driverName ? this.state.driverName : null}
                  // placeholder="Select Driver"
                  placeholder={this.context.translate("_SELECT_DRIVER_")}
                  style={{ width: 130 }}
                  showSearch
                  fieldNames={{ value: "driverId", label: "driverName" }}
                  onSelect={async (val) => {
                    console.log(val);
                    await this.setState({ driverName: val });
                    this.handleSearch();
                  }}
                  optionFilterProp="children"
                  options={this.jobManagementStore?.getDrivers}
                /> */}

                <Select
                  value={this.state.driverName ? this.state.driverName : null}
                  placeholder={this.context.translate("_SELECT_DRIVER_")}
                  style={{ width: 130 }}
                  onSelect={async (val) => {
                    // console.log(val);
                    await this.setState({ driverName: val });
                    this.handleSearch();
                  }}
                  showSearch
                  optionFilterProp="children"
                  // options={this.vehicleWorkingHoursStore?.assignAssetList}
                  filterOption={(inputValue, option) => {
                    if (option.children) {
                      return (
                        option.children
                          .toLowerCase()
                          .indexOf(inputValue.toLowerCase()) >= 0
                      );
                    }
                  }}
                >
                  {this.jobManagementStore?.getDrivers?.map((val) => {
                    return (
                      <Option value={val.driverId}>{val.driverName}</Option>
                    );
                  })}
                </Select>

                <Input
                  style={{ width: 280 }}
                  // placeholder="Customer Name"
                  placeholder={this.context.translate(
                    "_CUSTOMER_NAME_INVOICE_NO_"
                  )}
                  onChange={async (e) => {
                    await this.setState({ customerName: e.target.value });
                  }}
                />

                <DatePicker
                  defaultValue={dayjs()}
                  style={{ width: 130 }}
                  format="DD-MM-YYYY"
                  name="deliveryDateFrom"
                  // value={this.state.deliveryDateFrom}
                  onChange={(date) => {
                    if (date === null) {
                      this.setState({ deliveryDateFrom: "" });
                    } else {
                      const format = "YYYY-MM-DD";
                      this.setState({ deliveryDateFrom: date.format(format) });
                    }
                  }}
                />

                <DatePicker
                  defaultValue={dayjs()}
                  format="DD-MM-YYYY"
                  style={{ width: 130 }}
                  name="deliveryDateTo"
                  onChange={(date) => {
                    if (date === null) {
                      this.setState({ deliveryDateTo: "" });
                    } else {
                      const format = "YYYY-MM-DD";
                      this.setState({ deliveryDateTo: date.format(format) });
                    }
                  }}
                />
                <Button
                  icon={<SearchOutlined />}
                  type="primary"
                  onClick={async () => {
                    await this.searchClick();
                  }}
                >
                  {/* <SearchOutlined /> */}
                </Button>
              </Space>
            </Col>
            <Col span={2}>
              <Space
                style={{
                  float:
                    this.context.locale.direction === "ltr" ? "right" : "left",
                }}
              ></Space>
            </Col>
          </Row>

          <ListTable
            rowSelection={{
              selectedRowKeys: this.state.jobId,
              onChange: async (selectedRowKeys, selectedRows) => {
                // await this.setState({})
                await this.setState({ jobId: selectedRowKeys });
              },
            }}
            rowKey="Id"
            current={this.state.currentPage}
            pageSize={this.state.pageSize}
            total={this.jobManagementStore?.total}
            columns={this.columns}
            dataSource={this.jobManagementStore?.jobManagement}
            loading={this.jobManagementStore?.loading}
            onChange={this.handleTableChange}
          />

          {this.state.showUpload && (
            <>
              <Modal
                open={this.state.showUpload}
                // title="File Upload"
                title={this.context.translate("_FILE_UPLOAD_")}
                maskClosable={false}
                onOk={() => this.setState({ showUpload: false })}
                onCancel={() => this.setState({ showUpload: false })}
                footer={false}

                // style={{width:100}}
              >
                <Form
                  name="excelimport"
                  layout="vertical"
                  ref={this.formRef}
                  onFinish={this.onExcelSubmit}
                  style={{ padding: "0px 10px" }}
                >
                  {this.props.getUserData().isadmin === -1 && (
                    <Form.Item
                      name="companyId"
                      label={this.context.translate("_ACCOUNT_")}
                      rules={[{ required: true }]}
                    >
                      <CompanyAutocomplete
                        onChange={(companyId) => {
                          this.setState({ companyId: companyId.value });
                        }}
                        // style={{ width: 300 }}
                      />
                    </Form.Item>
                  )}
                  <Form.Item
                    // label="Assign Job"
                    label={this.context.translate("_ASSIGN_JOB_")}
                    name="jobType"
                    rules={[{ required: true }]}
                  >
                    <Select>
                      <Select.Option value="Driver">
                        {/* Driver */}
                        {this.context.translate(
                          "_FLEET_DASHBORD_HISTORY_TOOLTIP_DRIVER_"
                        )}
                      </Select.Option>

                      <Select.Option value="Sales Person">
                        {/* Sales Person */}
                        {this.context.translate("_SALES_PERSON_")}
                      </Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    // label="Excel Details(Id/Name)"
                    label={this.context.translate("_EXCEL_DETAILS_ID_NAME_")}
                    name="detailType"
                    rules={[{ required: true }]}
                  >
                    <Select>
                      <Select.Option value="id">
                        {this.context.translate("_Id_")}
                      </Select.Option>
                      <Select.Option value="name">
                        {this.context.translate("_FLEET_DASHBORD_NEWPOI_NAME_")}
                      </Select.Option>
                    </Select>
                  </Form.Item>

                  <Form.Item
                    name="excelFile"
                    // label="Excel File"
                    label={this.context.translate("_EXCEL_FILE_")}
                    className="uploadFile"
                    rules={[{ required: true }]}
                  >
                    <Upload
                      // status= 'error'
                      showProgress="true"
                      name="excelFile"
                      action={
                        config.baseURL + "/index.php?c=company&a=fileupload"
                      }
                      listType="text"
                      data={{ fileType: "jobimport", fieldName: "excelFile" }}
                      onChange={this.getUploadData}
                      maxCount={1}
                      // progress={{
                      //   strokeColor: {
                      //     '0%': '#108ee9',
                      //     '100%': '#87d068',
                      //   },

                      //   strokeWidth: 2,
                      //   format: (percent) => `${percent.toFixed(2)}%`,
                      // }}
                    >
                      <div>
                        <Button icon={<UploadOutlined />}>
                          {/* Click To Upload Excel File */}
                          {this.context.translate("_CLICKTOUPLOAD_EXCELFILE_")}
                        </Button>
                        {this.state.uploadProgress > 0 ? (
                          <Progress
                            percent={this.state.uploadProgress}
                            status="active"
                            type="line"
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </Upload>
                  </Form.Item>
                  <div className="formBtnGroup">
                    {/* css class */}

                    <Dropdown menu={this.menuProps}>
                      <Button className="formCancelButton">
                        {this.context.translate("_SAMPLE_")}
                      </Button>
                    </Dropdown>

                    <Button
                      type="primary"
                      className="formSaveButton"
                      htmlType="submit"
                      // loading={this.state.loading}
                    >
                      {this.context.translate("_UPLOAD_")}
                    </Button>
                  </div>
                </Form>
              </Modal>
            </>
          )}

          <Modal
            width={350}
            className="alert-model"
            title={
              <div>
                {" "}
                <i
                  style={{ color: "red", fontSize: "18px", marginRight: "5px" }}
                  class="bi bi-trash3"
                ></i>{" "}
                {/* Do you want to finish job? */}
                {this.context.translate("_FINISH_JOB_")}
              </div>
            }
            open={this.state.showModal}
            onCancel={() => this.setState({ showModal: false })}
            footer={[
              <div style={{ padding: "10px" }}>
                <Button
                  type="primary"
                  // key="yes"
                  onClick={(e) => {
                    this.finishJob(this.state.jobId);

                    this.setState({ showModal: false });
                  }}
                >
                  {/* Yes */}
                  {this.context.translate("_FLEET_DASHBORD_NEWPOI_REMOVEYES_")}
                </Button>
                <Button
                  // key="no"
                  onClick={() => this.setState({ showModal: false })}
                >
                  {/* No */}
                  {this.context.translate("_FLEET_RULELIST_CRONRULE_NO_")}
                </Button>
              </div>,
            ]}
          >
            {/* <p>Are you sure you want to finish job?</p> */}
            {this.context.translate("_SURE_TO_FINISH_JOB_")}
          </Modal>

          <Modal
            className="multiTracking-popup"
            maskClosable={false}
            title={
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                  style={{
                    padding: "8px",
                    display: "flex",
                    gap: "10px",
                  }}
                >
                  <CompanyAutocomplete
                    onChange={(value) => this.handleChange(value)}
                    allowClear={true}
                    style={{ width: 150 }}
                  />
                  <Input
                    style={{ width: 150 }}
                    //  placeholder="Vehicle"
                    placeholder={this.context.translate("_VEHICLES_")}
                  />

                  <DatePicker defaultValue={dayjs()} format="MM-DD-YYYY" />
                </div>
                <CloseOutlined
                  onClick={() => this.setState({ showmap: false })}
                />
              </div>
            }
            centered
            open={this.state.showmap}
            onCancel={() => this.setState({ showmap: false })}
            footer={null}
            width={750}
          >
            <DisplayLocationMap></DisplayLocationMap>
          </Modal>

          <Modal
            width={350}
            className="alert-model"
            title={
              <div>
                {" "}
                <i
                  style={{ color: "red", fontSize: "18px", marginRight: "5px" }}
                  class="bi bi-trash3"
                ></i>{" "}
                {/* Delete Job? */}
                {this.context.translate("_DELETE_JOB_")}
              </div>
            }
            open={this.state.jobId.length !== 0 && this.state.showDeleteModal}
            onCancel={() => this.setState({ showDeleteModal: false })}
            footer={[
              <Button
                // key="no"
                onClick={() => this.setState({ showDeleteModal: false })}
              >
                {this.context.translate("_FLEET_RULEDETAIL_ACTIVEDDL_NO_")}
              </Button>,
              <Button
                type="primary"
                onClick={(e) => {
                  this.deleteJob(this.state.jobId);

                  this.setState({ showDeleteModal: false, jobId: "" });
                }}
              >
                {this.context.translate("_FLEET_RULEDETAIL_ACTIVEDDL_YES_")}
              </Button>,
            ]}
          >
            <p>
              {/* Are you sure you want to delete? */}
              {this.context.translate("_SURE_TO_DELETE_")}
            </p>
          </Modal>

          {this.state.openJobmanagementForm && (
            <Modal
              bodyStyle={{ height: "645px" }}
              className="formShowModal" //  {/* css class */}
              // title="Add Job Details"
              title={this.context.translate("_ADD_JOB_DETAILS")}
              centered
              open={this.state.openJobmanagementForm}
              footer={null}
              onCancel={() => {
                this.setState({ openJobmanagementForm: false, Id: "" });
              }}
              width={665}
            >
              <div>
                <JobManagementAdd
                  Id={this.state.Id}
                  response={this.responseMsg}
                  closeModal={this.closeModal}
                />
              </div>
            </Modal>
          )}

          <Modal
            width={350}
            className="alert-model"
            title=<div>
              {" "}
              <i
                style={{ color: "red", fontSize: "18px", marginRight: "5px" }}
                class="bi bi-exclamation-triangle"
              ></i>{" "}
              {this.context.translate("_FLEET_ALERT_")}
            </div>
            open={this.state.showAlertModal}
            onOk={() => this.setState({ showAlertModal: false })}
            onCancel={() => this.setState({ showAlertModal: false })}
            okText="OK"
            cancelText="Cancel"
            footer={[
              <Button
                type="primary"
                onClick={() => this.setState({ showAlertModal: false })}
              >
                {/* Ok */}
                {this.context.translate(
                  "_FLEET_DASHBORD_VEHICLE_COMMAND_OKBTN_"
                )}
              </Button>,
            ]}
          >
            <p>{this.context.translate("_SELECT_JOB_")}</p>
          </Modal>

          <Modal
            width={350}
            className="alert-model"
            title=<div>
              {" "}
              <i
                style={{ color: "red", fontSize: "18px", marginRight: "5px" }}
                class="bi bi-exclamation-triangle"
              ></i>{" "}
              {this.context.translate("_FLEET_ALERT_")}
            </div>
            open={this.state.showCancelJobModal}
            onCancel={() => this.setState({ showCancelJobModal: false })}
            footer={[
              <Button
                onClick={() => this.setState({ showCofirmJobModal: true })}
              >
                {this.context.translate("_FLEET_RULEDETAIL_ACTIVEDDL_YES_")}
              </Button>,
              <Button
                onClick={() => this.setState({ showCancelJobModal: false })}
              >
                {this.context.translate("_FLEET_RULEDETAIL_CRONRULE_NO_")}
              </Button>,
            ]}
          >
            <p>Are you sure to cancel job?</p>
          </Modal>

          {this.state.showCofirmJobModal && (
            <Modal
              width={400}
              className="alert-model"
              title=<div>
                {" "}
                <i
                  style={{ color: "red", fontSize: "18px", marginRight: "5px" }}
                  class="bi bi-exclamation-triangle"
                ></i>{" "}
                {this.context.translate("_FLEET_ALERT_")}
              </div>
              open={this.state.showCancelJobModal}
              onCancel={() => this.setState({ showCancelJobModal: false })}
              footer={null}
            >
              <Form layout="vertical" onFinish={this.handleReasonToCancel}>
                <Form.Item
                  label={this.context.translate("_REASON_TO_CANCEL_JOB_")}
                  name="reason"
                  rules={[{ required: true }]}
                >
                  <Input></Input>
                </Form.Item>

                <div className="formBtnGroup">
                  <Button
                    type="primary"
                    className="formSaveButton"
                    htmlType="submit"
                    // loading={this.state.loading}
                  >
                    {this.context.translate("_FLEET_DASHBORD_NEWPOI_SAVEBTN_")}
                  </Button>
                </div>
              </Form>
            </Modal>
          )}
        </div>
      </>
    );
  }
}
JobManagementList.contextType = StoreContext;
export default observer(withRouter(JobManagementList));
