import React from "react";
import "../index.css";
import "../theme-login-style.css";

import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Form,
  Input,
  Image,
  message,
  Modal,
  Spin,
  Switch,
  QRCode,
} from "antd";
import withRouter from "../withRouter";
import { postApi } from "./Utility";
import { encode as base64_encode } from "base-64";
import Cookies from "universal-cookie";
import { StoreContext } from "../store/RootStore";
import { observer } from "mobx-react";

import LiveTrackingStore from "../store/LiveTrackingStore";
import EmailReportStore from "../store/EmailReportStore";
import ForgetPassStore from "../store/ForgetPassStore";
import config from "../config.js";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedLang: "english",
      loading: false,
      forgetpassmodal: false,
      engActiveBtn: true,
      araActiveBtn: false,
      forgetPassLoading: false,
      showGoogleQRCode: false,
      showQRCode: false,
      openModalForQRCode: false,
    };
    this.optionsWithDisabled = [
      {
        label: "English",
        value: "english",
        direction: "ltr",
      },
      {
        label: "عربي",
        value: "arabic",
        direction: "rtl",
      },
    ];

    // this.props.navigate('/home')
    //this.navigate = useNavigate();
    this.liveTrackingStore = new LiveTrackingStore(this);
    this.forgetPassStore = new ForgetPassStore(this);
    this.forgetFormRef = React.createRef(this);

    this.submitHandler = this.submitHandler.bind(this);
    this.onLangChange = this.onLangChange.bind(this);
    this.emailReportStore = new EmailReportStore(this);
    this.cookies = new Cookies();

    this.updateTitle();
    // this.updateIcons();
  }
  async submitHandler(values) {
    try {
      this.setState({ loading: true });
      var body = {
        userEmail: values.userEmail,
        password: values.password,
      };
      var postData = { tokenId: -1, user: body };

      const base64Input = await base64_encode(JSON.stringify(postData));

      var response = await postApi
        .getClient()
        .post("index.php?c=user&a=login", "postData=" + base64Input);
      console.log(response);
      // this.context.setRuleSelector(response.data.universal.ruleselectors)
      // console.log(response.data.universal.ruleselectors,"qwewreqrtrttry")
      // this.ruleSelector=response.data.universal.ruleselectors;

      this.context.rolePermission = response.data?.data?.rolePermission;
      this.emailReportStore.reportNames =
        response?.data?.data?.universal?.reports;
      if (response?.data?.data && response.data["success"] == "S") {
        // console.log(this.props.setCookie) //this.props.setCookie("userToken",response.data.token,{path:"/"});
        // console.log(response.data.data)
        // this.context.menuShow = true
        this.cookies.set("mapShow", 1);
        console.log(response.data.data.logo);
        this.context.menuShow = true;
        const coockieData = {
          userToken: response.data.data.token,
          userName: response.data.data.name,
          userCompanyId: response.data.data.companyId,
          logo: response.data.data.logo,
          userId: response.data.data.userId,
          isadmin: response.data.data.isadmin,
          issuperadmin: response.data.data.issuperadmin,
          rolePermission: response.data?.data?.rolePermission,
          photo: response.data?.data?.photo,
          mapSetting: response?.data?.data?.mapSetting,
          mapSetting2: response?.data?.data?.mapSetting2,
          roleId: response?.data?.data?.roleId,
          showLaunchingDashboard: config.project === "drive7" ? 0 : 1,
        };
        console.log(coockieData);
        this.context.mapSetting = response?.data?.data?.mapSetting;
        this.context.mapSetting2 = response?.data?.data?.mapSetting2;
        this.cookies.set("data", base64_encode(JSON.stringify(coockieData)), {
          path: "/",
        });

        if (values.remember) {
          this.cookies.set("logindetails", base64Input, { path: "/" });
        }
        await message.success("Login Successful", 2);

        if (coockieData.isadmin === 1) {
          // this.context.isAdmin = false;
          this.cookies.set("isAdmin", 1);
        } else {
          // this.context.isAdmin = true;
          this.cookies.set("isAdmin", -1);
        }
        if (coockieData.showLaunchingDashboard === 0) {
          this.props.navigate("/livetracking");
        } else {
          this.props.navigate("/lounchingDashboard");
        }
        // this.props.navigate("/lounchingDashboard");
      } else {
        message.error(response.data.errors.loginerror);
        this.setState({ loading: false });
        //  message.error(response.data.data.errors.loginerror);
      }
    } catch (e) {
      message.error(response?.data?.message || "Something went wrong. Retry.");
      //  message.error(response.data.data.errors.loginer
      //var message = e.response?.data?.message || "Something went wrong. Retry.";
    }
  }

  updateTitle = () => {
    const favicon = document.querySelector("link[rel='icon']");
    const appleTouchIcon = document.querySelector(
      "link[rel='apple-touch-icon']"
    );
    if (config.project === "drive7") {
      document.title = "Drive7";
      if (favicon) favicon.href = "/drive7.ico";
      if (appleTouchIcon) appleTouchIcon.href = "/drive7.ico";
    } else if (config.project === "awtltrack") {
      document.title = "AWTL";
      if (favicon) favicon.href = "/awtl.ico";
      if (appleTouchIcon) appleTouchIcon.href = "/awtl.ico";
    } else if (config.project === "starlingeye") {
      document.title = "Starlingeye";
      if (favicon) favicon.href = "/starling.ico";
      if (appleTouchIcon) appleTouchIcon.href = "/starling.ico";
    } else if (config.project === "trackeazy") {
      document.title = "Trackeazy";
      if (favicon) favicon.href = "/favicon.ico";
      if (appleTouchIcon) appleTouchIcon.href = "/favicon.ico";
    } else {
      document.title = "Trackeazy";
      if (favicon) favicon.href = "/favicon.ico";
      if (appleTouchIcon) appleTouchIcon.href = "/favicon.ico";
    }
  };

  // updateIcons = () => {
  //   const isSpecialCondition = config.project === "drive7"; // Define the condition

  //   return {
  //     favicon: isSpecialCondition ? "/drive7.ico" : "/drive7.ico",
  //     appleTouchIcon: isSpecialCondition ? "/drive7.ico" : "/drive7.ico"
  //   };

  // };
  onLangChange = (e) => {
    if (e) {
      this.context.setLocale("english");
      this.cookies.set("isArabic", 0);
      this.setState({ engActiveBtn: true, araActiveBtn: false });
      this.props.form[0].resetFields();
    } else {
      this.context.setLocale("arabic");
      this.cookies.set("isArabic", 1);
      this.setState({ engActiveBtn: false, araActiveBtn: true });
      this.props.form[0].resetFields();
    }
  };
  resetpass = () => {
    console.log("click");
    this.setState({ forgetpassmodal: true });
  };
  onFinishForgetPass = async (values) => {
    await this.setState({ forgetPassLoading: true });
    console.log(values);
    const resp = await this.forgetPassStore.forgetPass(values);

    // if (res.data.success === "success") {
    //   // Modal.success({
    //   //   content:this.context.translate( res.data.message),
    //   // });
    //  await message.success(res.data.message);
    //  await this.setState({forgetpassmodal:false})
    // } else {
    //   Modal.error({
    //     // content:res.data.errors[0].erroruser,
    //     title: this.context.translate("_ERROR_"),

    //     content:this.context.translate( res.data.errors[0].erroruser),
    //     // bodyStyle: { padding: "20px" ,background:"yellow"},
    //   });
    // }

    if (resp.data.success === "S") {
      message.success(resp.data.message);
      await this.setState({ forgetPassLoading: false });
      await this.setState({ forgetpassmodal: false });
    } else if (resp.data.success === "F0") {
      // resp.data.errors.map((value) => {

      message.error(this.context.translate(resp.data.errors[0].erroruser));
      await this.setState({ forgetPassLoading: false });
      // });
    }
  };
  // async onDelete(record) {
  //   console.log(record.simId, "record");
  //   const response = await this.simStore.deleteRecord({
  //     simId: record.simId,
  //   });

  // }
  handleAppleStates = () => {
    this.setState({
      openModalForQRCode: true,
      showQRCode: true,
      showGoogleQRCode: false,
    });
  };

  handleGoogleStates = () => {
    this.setState({
      openModalForQRCode: true,
      showGoogleQRCode: true,
      showQRCode: false,
    });
  };

  render() {
    return (
      <div
        className="theme-login-wrapper"
        style={{
          //height: "100%",
          //width: "60%",
          // position: "relative",
          // overflow: "hidden",
          backgroundImage: config.useBgVideo
            ? null
            : `url(${require("../assets/images/" +
                (config.loginBgImage
                  ? config.loginBgImage
                  : "new_login_bg.png"))})`,
          //backgroundSize: "cover",
          direction: this.context.locale.language === "english" ? "ltr" : "rtl",
        }}
      >
        {config.useBgVideo && (
          <video autoPlay muted loop>
            <source
              src={require("../assets/images/drive7/video-short.mp4")}
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        )}

        <div className={`login-container ${config.loginWindowAlign}Position`}>
          <div className="welcomeInfo">
            <p>Nice to see you again</p>
            <h1>Welcome Back</h1>
          </div>
          <div className="login-form">
            <div className="login-logo">
              <Image src={require("../assets/images/" + config.loginlogo)} />
            </div>
            <div className="languageBlock">
              <Switch
                checkedChildren="English"
                unCheckedChildren="عربي"
                onChange={this.onLangChange}
                defaultChecked={
                  Number(
                    JSON.parse(
                      this.cookies.get("isArabic")
                        ? this.cookies.get("isArabic")
                        : 0
                    )
                  ) === 1
                    ? false
                    : true
                }
              />
            </div>
            <Form
              name="normal_login"
              //className="login-form"
              layout="vertical"
              initialValues={{
                remember: true,
              }}
              form={this.props.form[0]}
              onFinish={this.submitHandler}
            >
              <Form.Item
                name="userEmail"
                label={this.context.translate(
                  "_FLEET_COMPANY_USERDETAIL_USERNAME_"
                )}
                className="formfield username"
                rules={[
                  {
                    required: true,
                    message: this.context.translate(
                      "_FLEET_COMPANY_USERNAME_PROVIDE_"
                    ),
                  },
                ]}
              >
                <Input
                  size="large"
                  placeholder={this.context.translate("_USERNAME_")}
                />
              </Form.Item>
              <Form.Item
                className="formfield password"
                label={this.context.translate(
                  "_FLEET_COMPANY_USERDETAIL_PASSWORD_"
                )}
                name="password"
                rules={[
                  {
                    required: true,
                    message: this.context.translate(
                      "_FLEET_COMPANY_PASSWORD_PROVIDE_"
                    ),
                  },
                ]}
              >
                <Input.Password
                  autoComplete="fail"
                  style={{ padding: "0 0" }}
                  type="password"
                  placeholder={this.context.translate(
                    "_FLEET_LOGIN_PASSWORDLBL_"
                  )}
                  size="large"
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                />
              </Form.Item>

              {/* <Form.Item> */}
              {/* <Row style={{ direction: this.context?.locale.direction }}> */}
              <div className="loginLinks">
                <Form.Item
                  className="loginCheckbox"
                  name="remember"
                  valuePropName="checked"
                  noStyle
                >
                  <Checkbox>
                    {this.context.translate("_FLEET_LOGIN_REMEMBER_")}
                  </Checkbox>
                </Form.Item>
                <Form.Item>
                  <div>
                    <a
                      className="forgotLink"
                      onClick={() => this.setState({ forgetpassmodal: true })}
                    >
                      {this.context.translate("_FLEET_LOGIN_FRWDBTN_")}?
                    </a>
                  </div>
                </Form.Item>
              </div>
              {/* </Row> */}
              {/* </Form.Item> */}

              <Form.Item>
                <Button
                  size="large"
                  type="primary"
                  htmlType="submit"
                  loading={this.state.loading}
                  className="loginBtn"
                  block
                >
                  {this.context.translate("_FLEET_LOGIN_LOGINBTN_")}
                </Button>
              </Form.Item>
            </Form>
            <Modal
              open={this.state.openModalForQRCode}
              onCancel={() => this.setState({ openModalForQRCode: false })}
              footer={null}
              title="Scan QRCode to Download App!"
              width={350}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                {this.state.showGoogleQRCode && (
                  <div style={{ marginTop: 20, alignItems: "center" }}>
                    <QRCode value="https://play.google.com/store/apps/details?id=com.pmis.trackeazy" />
                  </div>
                )}

                {this.state.showQRCode && (
                  <div style={{ marginTop: 20, alignItems: "center" }}>
                    <QRCode value="https://apps.apple.com/ae/app/trackeazy-pro/id6529531659" />
                  </div>
                )}
              </div>
            </Modal>
          </div>
          {this.state.forgetpassmodal && (
            <Modal
              title={this.context.translate("_FLEET_LOGIN_FRWDBTN_")}
              open={this.state.forgetpassmodal}
              onCancel={() => this.setState({ forgetpassmodal: false })}
              footer={null}
              maskClosable={false}
              centered
              style={{ padding: "20px", maxWidth: "440px" }}
            >
              <div>
                <Spin spinning={this.state.forgetPassLoading} fullscreen>
                  <Form
                    name="basic"
                    ref={this.forgetFormRef}
                    onFinish={this.onFinishForgetPass}
                  >
                    <Form.Item
                      name="useremail"
                      rules={[
                        {
                          required: true,
                          type: "email",
                          message: this.context.translate("_ENTER_EMAILID_"),
                        },
                      ]}
                    >
                      <Input
                        className="customInput"
                        placeholder={this.context.translate("_ENTER_EMAILID_")}
                      />
                    </Form.Item>
                    <br />
                    <Form.Item>
                      <div className="forgetPassBtns">
                        <Button
                          className="commonBtns blueBtn"
                          htmlType="submit"
                        >
                          {this.context.translate(
                            "_FLEET_DASHBORD_VEHICLE_COMMAND_SENDBTN_"
                          )}
                        </Button>
                        <Button
                          className="commonBtns grayBtn"
                          onClick={() => {
                            this.forgetFormRef.current?.resetFields();
                            this.setState({ forgetpassmodal: false });
                          }}
                        >
                          {this.context.translate(
                            "_FLEET_DASHBORD_SHOWPOI_CANCELBTN_"
                          )}
                        </Button>
                      </div>
                    </Form.Item>
                  </Form>
                </Spin>
              </div>
            </Modal>
          )}
        </div>
      </div>
    );
  }
}

Login.contextType = StoreContext;
export default observer(withRouter(Login));
