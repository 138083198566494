import { Image, Modal } from "antd";
import React, { useContext } from "react";
import { StoreContext } from "../../../store/RootStore";
import { observer } from "mobx-react";
import withRouter from "../../../withRouter";
import config from "../../../config";
//import baseConfig from "../../../baseconfig.json";
import { shouldShowComponent } from "../../../utils/componentVisibility";

const AddLoginFields = (props) => {
  console.log("props", props);
  const shouldShow = shouldShowComponent(config.project, "AddLoginFields");
  console.log("shouldShow**", shouldShow);
  const storeContext = useContext(StoreContext); // Use the context here

  if (!shouldShow) {
    return null; // Do not render if the condition is not met
  }

  return (
    <>
      <div className="download-btn" style={{ textAlign: "right" }}>
        {" "}
        <Image
          style={
            {
              // marginInlineEnd: "20px",
              // width: "60%",
              // height: "32px",
            }
          }
          src={require("../../../assets/images/google-play-btn.png")}
          onClick={() => {
            console.log("sdfsdffff");
            props.handleGoogleStates();
            // this.handleGoogleButtonClick();
            // props.setState({
            //  openModalForQRCode: true,
            //   showGoogleQRCode: true,
            //   showQRCode: false,
            // });
          }}
        ></Image>
        <Image
          // style={{
          //   // marginInlineStart: "35px",
          //   width: "60%",
          //   height: "32px",
          // }}
          src={require("../../../assets/images/app-store-btn.png")}
          onClick={() => {
            props.handleAppleStates();
            // this.handleButtonClick();
            // props.setState({
            //   openModalForQRCode: true,
            //   showQRCode: true,
            //   showGoogleQRCode: false,
            // });
          }}
        ></Image>
      </div>
    </>
  );
};

AddLoginFields.contextType = StoreContext;
export default observer(withRouter(AddLoginFields));
